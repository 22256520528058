.locationAddress {
  display: flex;
  flex-direction: column;
  padding: 31px;
  label {
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    text-transform: capitalize;
    color: #0e1738;
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      text-transform: capitalize;
      color: #0e1738;
      margin-bottom: 16px;
      margin-top: 3px;
    }
  }

  input {
    width: 315px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #64247e;
    box-sizing: border-box;
    outline: none;
    &[dir="rtl"] {
      padding-right: 10px;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.checkin {
  display: flex;
  flex-direction: column;
  padding: 31px;
  label {
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    text-transform: capitalize;
    color: #0e1738;
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      text-transform: capitalize;
      color: #0e1738;
      margin-bottom: 16px;
      margin-top: 3px;
    }
  }

  input {
    width: 315px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #64247e;
    box-sizing: border-box;
    outline: none;
    &[dir="rtl"] {
      padding-right: 10px;
      padding-left: 10px;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}
@media (max-width: 500px) {
  .NumberOfNights {
    padding-bottom: 200px;
  }
}

.preferencesScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
input {
  width: 315px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #64247e;
  box-sizing: border-box;
  outline: none;
  &[dir="rtl"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  border-radius: 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
}
.TripDaysSelector {
  font-size: 2.5vh;
  // width: 40px;
  margin: 0 15px;
  padding: 2px;
  border-radius: 9px;
  text-align-last: center;
  outline: none;
}
.TripDaysOption {
  font-size: 2vh;
  // width: 40px;
  // padding: 2px;
  // border-radius: 9px;
  text-align-last: center;
  outline: none;
}

.description1 {
  margin-bottom: 2vh;
  text-align: center;
  // font-weight: 600;
  font-size: 2.5vh;
}
.description2 {
  margin-bottom: 2vh;
  margin-top: 4vh;
  text-align: center;
  // font-weight: 600;
  font-size: 2.5vh;
}
