.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 120px;
  
}

.interestsText1 {
  margin-top: 100px;
  font-size: 2.5vh;
  padding: 1vh 5vh 2vh 5vh;
  
}
.interestsText2 {
  padding: 0vh 5vh 2vh 5vh;
  font-size: 1.8vh;
  color: #5f657a;
}
.interest {
  width: 140px;
  height: 133px;
  background: #ffffff;
  border: 1px solid rgba(14, 23, 56, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 17px;

  .imgContainer {
    width: 100%;
    height: 90px;
    position: relative;
    text-align: center;

    .selectOverlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .check {
        background-color: #fbfbfb;
        font-size: 16px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .selectOverlay {
    opacity: 1;
  }

  .interestTitle {
    font-family: inherit, sans-serif;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    color: #4d4e72;
    padding: 10px;
  }
  .imgBorder {
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid rgba(14, 23, 56, 0.24);
  }

  .planImage {
    height: 70%;
    width: 60%;
    object-position: center;
    object-fit: scale-down;
    margin-top: 2vh;
  }
}
